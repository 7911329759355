body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Load custom fonts */
@font-face {
  font-family: 'Londrina Solid';
  src: url(./fonts/Londrina_Solid/LondrinaSolid-Black.ttf);
  src: url(./fonts/Londrina_Solid/LondrinaSolid-Regular.ttf);
}
@font-face {
  font-family: 'PT Root UI';
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Regular.woff2) format('woff2'),
    url(./fonts/PT_Root_UI/PT-Root-UI_Regular.woff) format('woff');
}
@font-face {
  font-family: 'PT Root UI';
  font-weight: 500;
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Medium.woff2) format('woff2'),
    url(./fonts/PT_Root_UI/PT-Root-UI_Medium.woff) format('woff');
}

@font-face {
  font-family: 'PT Root UI';
  font-weight: bold;
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Bold.woff2) format('woff2'),
    url(./fonts/PT_Root_UI/PT-Root-UI_Bold.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-Regular.woff2) format('woff2'),
    url(./fonts/Inter/Inter-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(./fonts/Inter/Inter-SemiBold.woff2) format('woff2'),
    url(./fonts/Inter/Inter-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(./fonts/Inter/Inter-Bold.woff2) format('woff2'),
    url(./fonts/Inter/Inter-Bold.woff) format('woff');
}