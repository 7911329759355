.section span {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Londrina Solid';
}

.section h4 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
}

.section h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0px !important;
  margin-top: 3px;
}

.infoButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* text-decoration: underline; */
  display: inline;
  margin: 0;
  padding: 0;
}
.infoButton:disabled {
  color: #8c8d92;
  text-decoration: none;
  cursor: default;
}

.infoButton:hover,
.infoButton:hover {
  text-decoration: none;
  color: rgb(95, 95, 95);
  opacity: 1;
}

.wrapper {
  padding-left: 2.5rem;
  padding-right: 0rem;
  cursor: pointer;
  margin-top: 0.3rem;
}

.timerWrapper {
  display: flex;
  margin-top: 1px;
}

.timerSection span {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.timerSection {
  margin-right: 0.5rem;
}

.timerSectionFinal {
  margin-right: 0rem;
}

.timerSectionFinal span {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.leftCol {
  margin-top: 1px;
}

@media (max-width: 992px) {
  .section h4 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 6px;
  }

  .section h2 {
    font-size: 23px;
  }

  .section {
    justify-content: space-between;
  }

  .wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }

  .leftCol {
    margin-top: 0px;
    padding-left: 0.5rem;
  }

  .timerSection span {
    font-size: 23px;
  }

  .timerSectionFinal span {
    font-size: 23px;
  }

  .title {
    font-size: 18px;
    margin-top: 1.4rem;
  }

  .timeLeft {
    padding-right: 0.5rem;
  }
}
